import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'uikit/box';
import { Typography } from 'uikit/typography';
import AuthFormLogo from './authFormLogo';
import AuthFormIntellumLogo from './authFormIntellumLogo';

const AuthForm = ({ title, description, error, children }) => (
    <div className="authentication">
        <div className="authentication-form">
            <AuthFormLogo />
            {title && (
                <Typography fontStyle="title-3" spacing="x-small">
                    {title}
                </Typography>
            )}
            {description && (
                <Typography fontStyle="small" spacing="x-small">
                    {description}
                </Typography>
            )}
            {error && (
                <Box
                    marginBottom="regular"
                    padding="x-small"
                    style={{
                        borderLeft: `2px solid #e3002c`,
                        background: 'rgba(208, 44, 53, 0.05)',
                    }}
                >
                    <Typography fontStyle="small" theme="warning" fontWeight="bold">
                        {error}
                    </Typography>
                </Box>
            )}

            {children}
        </div>
        <AuthFormIntellumLogo />
    </div>
);

AuthForm.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    error: PropTypes.string,
};

export default AuthForm;
