import React from 'react';
import { string } from 'prop-types';
import { Typography } from 'uikit/typography';
import getRedirectURL from '../helpers/getRedirectURL';
import { usePublicPath } from '../../../publicPath';

const AuthFormOAuthProviderButton = ({ logo, text, endpoint }) => (
    <button
        className="authentication-oauth-button"
        onClick={() => {
            let url = `${window.appURL}${endpoint}`;
            const redirectTo = getRedirectURL(true);
            if (redirectTo) {
                url += `?redirectTo${encodeURIComponent(redirectTo)}`;
            }
            window.location = url;
        }}
    >
        <img
            src={`${usePublicPath()}/assets/${logo}`}
            className="authentication-oauth-button-image"
            alt="logo"
        />
        <Typography
            component="span"
            fontStyle="small"
            fontWeight="bold"
            textAlign="center"
            className="authentication-oauth-button-text"
        >
            {text}
        </Typography>
    </button>
);

AuthFormOAuthProviderButton.propTypes = {
    logo: string.isRequired,
    text: string.isRequired,
    endpoint: string.isRequired,
};

export default AuthFormOAuthProviderButton;
